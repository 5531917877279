<template>
<div class="Details">
   <div class="DetailsBox">
      <h1 v-html="NewDetailsDate.title"></h1>
      <h2>发布时间：{{NewDetailsDate.publish_time}}</h2>
      <div v-html="NewDetailsDate.Content" class="DetailsMain">
      </div>
   </div>
   <FooterB></FooterB>
</div>
</template>

<script>
import FooterB from "./FooterB";
export default {
   name: "NewsPage",
   components: {
      FooterB,
   },
   data() {
      return {
         NewDetailsDate: {},
      };
   },
   created() {
      if (this.$route.params.id) {
         this.axios.get(Global.NEWS_DETAIL_API + `${ this.$route.params.id}`).then((res) => {
            this.NewDetailsDate = res.data;
            localStorage.setItem("news_page", JSON.stringify(res.data))
         });
      } else {
         this.NewDetailsDate = JSON.parse(localStorage.getItem("news_page"))
      }
   },
};
</script>

<style lang="scss" scoped>
.Details {
   margin-top: 80px;
}

.DetailsBox {
   width: 100%;
   margin: 0 auto;
   box-sizing: border-box;
   padding: 0 20px 30px;

   /deep/ img {
      display: block;
      width: 300px;
      height: auto;
      margin: 0 auto;
   }

   h1 {
      font-size: 18px;
      font-weight: bold;
      color: #002060;
      margin-bottom: 20px;
      text-align: center;
      line-height: 30px;
   }

   h2 {
      text-align: center;
      font-size: 12px;
      font-weight: normal;
      margin-bottom: 30px;
   }

   /deep/ .DetailsMain {
      font-size: 18px;
   }
}
</style>
